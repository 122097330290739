import { ILocation, ISchedulerLocation } from '@/services/types';
import { JobSearchGrade, JobSearchSpeciality } from '@/types/jobs';
import { PreferredWorkLocation } from '@/types/registration';
import { getCookie, setCookie } from 'cookies-next';

const JOB_SEARCH_GRADE_COOKIE = 'mr-job-search-grade';
const JOB_SEARCH_SPECIALITY_COOKIE = 'mr-job-search-speciality';
const JOB_SEARCH_LOCATION_COOKIE = 'mr-job-search-location';
const JOB_SEARCH_COOKIE_LIFE_IN_MS = 24 * 60 * 60 * 1000; // 24 hours

export type JobSearchGradeCookie = JobSearchGrade | null;
export type JobSearchSpecialityCookie = JobSearchSpeciality | null;
export type JobSearchLocationCookie = {
  name: string;
  state?: string;
  country: string;
} | null;

export const getGradeCookie = (): JobSearchGradeCookie => {
  const value = getCookie(JOB_SEARCH_GRADE_COOKIE);
  if (value && typeof value === 'string') {
    return value as JobSearchGrade;
  }
  return null;
};

export const setGradeCookie = (grade: JobSearchGrade) => {
  setCookie(JOB_SEARCH_GRADE_COOKIE, grade, {
    expires: new Date(Date.now() + JOB_SEARCH_COOKIE_LIFE_IN_MS),
  });
};

export const getSpecialityCookie = (): JobSearchSpecialityCookie => {
  const value = getCookie(JOB_SEARCH_SPECIALITY_COOKIE);
  if (value && typeof value === 'string') {
    return value as JobSearchSpeciality;
  }
  return null;
};

export const setSpecialityCookie = (speciality: JobSearchSpeciality) => {
  setCookie(JOB_SEARCH_SPECIALITY_COOKIE, speciality, {
    expires: new Date(Date.now() + JOB_SEARCH_COOKIE_LIFE_IN_MS),
  });
};

export const getLocationCookie = (): JobSearchLocationCookie => {
  const value = getCookie(JOB_SEARCH_LOCATION_COOKIE);
  if (value && typeof value === 'string') {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch (_e) {
      return null;
    }

    if (parsedValue?.name && parsedValue?.country) {
      return {
        name: parsedValue.name,
        state: parsedValue?.state,
        country: parsedValue.country,
      };
    }
  }
  return null;
};

export const setLocationCookie = (locationCoordinate: ILocation | ISchedulerLocation) => {
  const value = JSON.stringify({
    name: locationCoordinate.name,
    state: (locationCoordinate as ILocation)?.state,
    country: locationCoordinate.country,
  });

  setCookie(JOB_SEARCH_LOCATION_COOKIE, value, {
    expires: new Date(Date.now() + JOB_SEARCH_COOKIE_LIFE_IN_MS),
  });
};

export const mapJobSearchLocationCookieToPreferredWorkLocation = (
  locationCookie: JobSearchLocationCookie,
): PreferredWorkLocation | null => {
  if (!locationCookie) {
    return null;
  }

  // country level
  if (locationCookie.name === 'New Zealand') {
    return {
      key: 'New Zealand',
      value: 'Anywhere in New Zealand',
    };
  }

  if (locationCookie.name === 'Australia') {
    return {
      key: 'Australia',
      value: 'Anywhere in Australia',
    };
  }

  // NZ locations
  if (locationCookie.country === 'New Zealand') {
    if (locationCookie.name === 'South Island') {
      return {
        key: 'South Island of New Zealand',
        value: 'South Island of New Zealand',
      };
    }

    if (locationCookie.name === 'North Island') {
      return {
        key: 'North Island of New Zealand',
        value: 'North Island of New Zealand',
      };
    }

    return {
      key: 'New Zealand',
      value: 'Anywhere in New Zealand',
    };
  }

  // AU locations
  if (locationCookie.country === 'Australia') {
    if (locationCookie.name === 'Victoria' || locationCookie?.state === 'Victoria') {
      return {
        key: 'Victoria Australia',
        value: 'Victoria Australia',
      };
    }

    if (locationCookie.name === 'New South Wales' || locationCookie?.state === 'New South Wales') {
      return {
        key: 'New South Wales Australia',
        value: 'New South Wales Australia',
      };
    }

    if (locationCookie.name === 'Queensland' || locationCookie?.state === 'Queensland') {
      return {
        key: 'Queensland Australia',
        value: 'Queensland Australia',
      };
    }

    if (locationCookie.name === 'South Australia' || locationCookie?.state === 'South Australia') {
      return {
        key: 'South Australia',
        value: 'South Australia',
      };
    }

    if (
      locationCookie.name === 'Australian Capital Territory' ||
      locationCookie?.state === 'Australian Capital Territory'
    ) {
      return {
        key: 'Australian Capital Territory',
        value: 'Australian Capital Territory',
      };
    }

    if (locationCookie.name === 'Tasmania' || locationCookie?.state === 'Tasmania') {
      return {
        key: 'Tasmania Australia',
        value: 'Tasmania Australia',
      };
    }

    if (
      locationCookie.name === 'Western Australia' ||
      locationCookie?.state === 'Western Australia'
    ) {
      return {
        key: 'Western Australia',
        value: 'Western Australia',
      };
    }

    if (
      locationCookie.name === 'Northern Territory' ||
      locationCookie?.state === 'Northern Territory'
    ) {
      return {
        key: 'Northern Territory Australia',
        value: 'Northern Territory Australia',
      };
    }
  }

  return null;
};
