import DropdownList from '@/components/DropdownList';
import LocationSearchAutocomplete from '@/components/LocationSearchAutocomplete';
import { ILocation, ISchedulerLocation } from '@/services/types';
import { JobSearchGrade } from '@/types/jobs';
import React, { ReactElement } from 'react';

import { Button } from '@material-ui/core';

import styles from './JobSearchForm.module.scss';

export type JobSearchFormProps = {
  grade?: JobSearchGrade;
  onGradeChange: (value: JobSearchGrade) => void;
  locationCoordinate: ILocation | ISchedulerLocation | null;
  onLocationCoordinateChange: (value: ILocation | ISchedulerLocation | null) => void;
  onSubmit: () => void;
  getGradeOptions: () => ReactElement[];
};

const JobSearchForm = ({
  grade,
  onGradeChange,
  locationCoordinate,
  onLocationCoordinateChange,
  getGradeOptions,
  onSubmit,
}: JobSearchFormProps) => (
  <div data-testid={'job-search-container'} className={styles.container}>
    <div className={styles.gridContainer}>
      <div className={styles.gridItemGrade}>
        <DropdownList
          id="grade"
          label="I'm a..."
          value={grade}
          testId="job-search-grade-input"
          onChange={e => {
            onGradeChange(('' + e.target.value) as JobSearchGrade);
          }}
          renderOptions={getGradeOptions}
        />
      </div>

      <div className={styles.gridItemLocation}>
        <LocationSearchAutocomplete
          selectedLocation={locationCoordinate}
          onSelectedLocationChange={onLocationCoordinateChange}
          label={'Looking for work in...'}
          placeholder={'Country, State, or City'}
        />
      </div>

      <div className={styles.gridItemSubmit}>
        <Button
          fullWidth
          type="submit"
          onClick={onSubmit}
          color="secondary"
          size="medium"
          variant="contained"
          data-testid="job-search-submit"
        >
          Search
        </Button>
      </div>
    </div>
  </div>
);

export default JobSearchForm;
