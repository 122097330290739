import JobSearchForm from '@/modules/JobSearchBox/components/JobSearchForm/JobSearchForm';
import { ILocation, ISchedulerLocation } from '@/services/types';
import { JobSearchGrade, JobSearchGrades } from '@/types/jobs';
import { generateJobListingsUrlObject } from '@/utils/jobListingHelper';
import { DEFAULT_RADIUS, getGradeOptions } from '@/utils/jobSearchBoxOptions';
import { setGradeCookie, setLocationCookie } from '@/utils/jobSearchCookies';
import { useAnalytics } from '@/utils/useAnalytics';
import { getJobGrades } from '@/utils/useRequest';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

const JobSearchBox = () => {
  const router = useRouter();
  const track = useAnalytics();

  // grade list
  const [grades, setGrades] = useState<JobSearchGrades | null>([
    { value: 'Loading', key: 'Loading' },
  ]);

  // form state
  const [grade, setGrade] = useState<JobSearchGrade | undefined>(undefined);
  const [locationCoordinate, setLocationCoordinate] = useState<
    ILocation | ISchedulerLocation | null
  >(null);
  // end of form state

  // fetch the list of grades on initial load
  useEffect(() => {
    getJobGrades()
      .then(grades => setGrades(grades))
      .catch(() => null); // the error get reported by makeRequestAndNoticeErrors, and we don't show any notification to the user
  }, []);

  function handleSubmit() {
    const lon = (locationCoordinate as ILocation)?.position?.lon.toString();
    const lat = (locationCoordinate as ILocation)?.position?.lat.toString();

    const search = {
      grade,
      location: (locationCoordinate as ISchedulerLocation)?.searchLocations?.join(';'),
      lon,
      lat,
      locationName: locationCoordinate?.name,
      radius: lat && lon ? DEFAULT_RADIUS : undefined,
      page: 1,
    };

    if (grade) {
      setGradeCookie(grade);
    }

    if (locationCoordinate) {
      setLocationCookie(locationCoordinate);
    }

    track('Job Search', {
      ...search,
      autocompleteLocationSelectedType: search.lon ? 'specific' : 'scheduler',
      autocompleteLocationRadius: null,
      jobSearchSimple: true,
    });

    return router.push(generateJobListingsUrlObject(search));
  }

  function getGradeOpts() {
    return getGradeOptions(grades);
  }

  return (
    <JobSearchForm
      grade={grade}
      onGradeChange={setGrade}
      locationCoordinate={locationCoordinate}
      onLocationCoordinateChange={setLocationCoordinate}
      onSubmit={handleSubmit}
      getGradeOptions={getGradeOpts}
    />
  );
};

export default JobSearchBox;
