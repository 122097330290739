import { Module } from '@/types/cms';
import { FAQPageJsonLd } from 'next-seo';
import { Question } from 'next-seo/lib/jsonld/faqPage';
import dynamic from 'next/dynamic';
import React from 'react';

const SingleCoverComponent = dynamic(() => import('@/cmsComponents/SingleCoverComponent'));
const ArticleSnippet = dynamic(() =>
  import('@/cmsComponents/ArticleSnippet').then(mod => mod.ArticleSnippet),
);
const CMSButton = dynamic(() => import('@/cmsComponents/Button').then(mod => mod.CMSButton));
const CMSImageText = dynamic(() =>
  import('@/cmsComponents/ImageText').then(mod => mod.CMSImageText),
);
const CMSText = dynamic(() => import('@/cmsComponents/Text').then(mod => mod.CMSText));
const CMSVideoText = dynamic(() =>
  import('@/cmsComponents/VideoText').then(mod => mod.CMSVideoText),
);
const GeneralImageSlider = dynamic(() =>
  import('@/cmsComponents/GeneralImageSlider').then(mod => mod.GeneralImageSlider),
);
const FrequentlyAskedQuestions = dynamic(() => import('@/cmsComponents/FrequentlyAskedQuestions'));
const CMSForm = dynamic(() => import('@/cmsComponents/Form').then(mod => mod.CMSForm));
const JobSnippet = dynamic(() => import('@/cmsComponents/JobSnippet').then(mod => mod.JobSnippet));
const Testimonial = dynamic(() =>
  import('@/cmsComponents/Testimonial').then(mod => mod.Testimonial),
);

const ModuleMap = {
  'text-collection': CMSText,
  'image-collection': CMSImageText,
  'cover-collection': SingleCoverComponent,
  'testimonial-collection': Testimonial,
  'faq-collection': FrequentlyAskedQuestions,
  'article-snippet-collection': ArticleSnippet,
  'general-image-slider': GeneralImageSlider,
  'video-collection': CMSVideoText,
  'job-snippet-collection': JobSnippet,
  'button-collection': CMSButton,
  'forms-collection': CMSForm,
};

const renderFaqs = (modules: Module[]): React.ReactNode => {
  const questionAnswers: Question[] = [];

  for (const item of modules) {
    if (item.__component !== 'faq-collection.faq-collection' || !item.faq_collections) {
      continue;
    }
    for (const faq of item.faq_collections) {
      if (faq.answerPlainText) {
        questionAnswers.push({
          questionName: faq.question,
          acceptedAnswerText: faq.answerPlainText,
        });
      }
    }
  }

  return questionAnswers.length > 0 ? <FAQPageJsonLd mainEntity={questionAnswers} /> : null;
};

export const CmsModuleList = ({ modules }: { modules: Module[] }) => {
  return (
    <>
      {renderFaqs(modules)}
      {modules.map(module => {
        const moduleType = module.__component.split('.')[0];
        const Comp = ModuleMap[moduleType];
        const key = `${module.id}_${module.__component}`;
        return Comp ? <Comp key={key} {...module} /> : null;
      })}
    </>
  );
};

export default CmsModuleList;
