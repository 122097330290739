import React, { ReactElement } from 'react';

import { FormControl, InputLabel, Select, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './DropdownList.module.scss';

export interface DropdownListProps {
  id: string;
  label: string;
  value?: string;
  testId: string;
  disabled?: boolean;
  tooltipMessage?: string;
  onChange: (event) => void;
  renderOptions: () => Array<ReactElement | null>;
}

const DropdownList = ({
  id,
  label,
  value,
  testId,
  disabled = false,
  tooltipMessage = '',
  onChange,
  renderOptions,
}: DropdownListProps) => (
  <FormControl className={styles.container} fullWidth data-testid={`select-container-${id}`}>
    <InputLabel id={`label-${id}`} shrink={value !== undefined}>
      {label}
    </InputLabel>
    <Tooltip title={tooltipMessage} interactive={!tooltipMessage}>
      <Select
        id={id}
        labelId={`label-${id}`}
        value={value || ''}
        IconComponent={ExpandMoreIcon}
        onChange={onChange}
        inputProps={{ 'data-testid': testId }}
        disabled={disabled}
      >
        {renderOptions()}
      </Select>
    </Tooltip>
  </FormControl>
);

export default DropdownList;
