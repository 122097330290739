import axios from 'axios';
import qs from 'querystring';

import { ISearchLocationResponse } from './types';

const api = axios.create({
  paramsSerializer: params => qs.stringify(params),
});

export const searchLocation = async (term: string): Promise<ISearchLocationResponse> => {
  try {
    const response = await api.get('/api/locations', {
      params: {
        q: term,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to load locations', { cause: error });
  }
};
