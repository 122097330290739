import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { searchLocation } from 'services/locationService';
import { ILocation, ISchedulerLocation } from 'services/types';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import styles from './LocationSearchAutocomplete.module.scss';

export interface LocationSearchAutoCompleteProps {
  selectedLocation: ILocation | ISchedulerLocation | null;
  onSelectedLocationChange: (data: ILocation | null | string) => void;
  label?: string;
  placeholder?: string;
}

export const LocationSearchAutocomplete = ({
  selectedLocation = { name: '' },
  onSelectedLocationChange,
  label = 'Country, State, or City',
  placeholder = 'Country, State, or City',
}: LocationSearchAutoCompleteProps) => {
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openOptions, setOpenOptions] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSearchTermChange = debounce((term: string) => setSearchTerm(term), 500);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      setLocations([]);
      setLoading(true);
      setOpenOptions(true);

      const fetchLocations = async () => {
        const data = await searchLocation(searchTerm);

        const mappedLocations = data.locations
          .map(
            (loc, idx) =>
              ({
                ...loc,
                divider: idx === data.locations.length - 1,
              } as ILocation),
          )
          .concat(data.results);

        setLocations(mappedLocations);
        setLoading(false);
      };

      fetchLocations().catch(() => setOpenOptions(false));
    } else {
      setOpenOptions(false);
    }
  }, [searchTerm]);

  return (
    <Autocomplete
      value={selectedLocation}
      onChange={(_event, value) => {
        onSelectedLocationChange(value);
        setOpenOptions(false);
      }}
      ListboxProps={{ style: { maxHeight: 250, overflow: 'auto' } }}
      data-testid="location-search-autocomplete"
      options={locations}
      autoHighlight={true}
      filterOptions={options => options}
      popupIcon={<></>} // Remove the dropdown icon
      clearOnBlur={true}
      open={openOptions}
      loading={!locations.length && loading}
      loadingText={
        <Box display="flex">
          <Box m="auto">
            <CircularProgress />
          </Box>
        </Box>
      }
      noOptionsText={
        <div data-testid="job-search-no-options">
          The location <i>{searchTerm}</i> cannot be found.
        </div>
      }
      getOptionLabel={(option: ILocation) => option.name}
      getOptionSelected={(option, value) => option.name === value.name}
      renderOption={(option: ILocation) => (
        <Box className={option.divider ? styles.divider : ''}>
          <div>
            <span data-testid={`${option.name}${option.city ? option.city : ''}`}>
              {option.name}
            </span>
            <span className={styles.locationSubTitle}>
              {[option.city, option.state, option.country].filter(val => !!val).join(', ')}
            </span>
          </div>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={label}
          inputProps={{
            ...params.inputProps,
            maxLength: 200,
            'data-testid': 'job-search-location-autocomplete-input',
          }}
          onFocus={() => {
            setOpenOptions(false);
            setSearchTerm('');
          }}
          onChange={e => onSearchTermChange(e.target.value)}
          onBlur={() => setOpenOptions(false)}
        />
      )}
    />
  );
};

export default LocationSearchAutocomplete;
